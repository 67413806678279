import { graphql } from 'gatsby';

import React from 'react';
import { QuerySiteArgs, Mdx } from '../generated/graphql-types';
import { LayoutProps, withLayout } from '../components/Layout';
import Helmet from 'react-helmet';
import { FixedMasthead } from '../components/Masthead/FixedMasthead.component';
import styled from '../styled-components';
import { BaseAnchorStyles, Constrain } from '../components/ui';

import { MDXRenderer } from 'gatsby-plugin-mdx';

import { PageHeader } from '../components/PageHeader/PageHeader.component';
import { AfterContentContact } from '../components/AfterContentContact/AfterContentContact.component';
import CallToContact from '../components/CallToContact/CallToContact.component';
import { BannerAdvert } from '../components/BannerAdvert/BannerAdvert.component';
import wmwSquareLogo from '../images/icon.png';

interface ICaseStudyProps extends LayoutProps {
    data: {
        caseStudy: Mdx; // MDX types
        site: QuerySiteArgs;
    };
}

const CaseStudyPageWrapper = styled.article`
    position: relative;
    padding-bottom: 2rem;
    margin-bottom: 2rem;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 170px;
        background-color: ${({ theme }) => theme.colors.orange.color};
        height: 2px;
        left: 50%;
        transform: translateX(-50%);
    }

    a {
        ${BaseAnchorStyles}

        &:hover {
            text-decoration-color: ${({ theme }) => theme.colors.orange.color};
        }
    }
`;

const CaseStudyPage = (props: ICaseStudyProps) => {
    const { frontmatter, body, fields, excerpt } = props.data.caseStudy;
    const { siteUrl } = props.data.site.siteMetadata;
    const socialImage =
        frontmatter.socialImage && frontmatter.socialImage.publicURL;

    return (
        <div itemScope itemType="http://schema.org/Article">
            <Helmet>
                <title>{`${frontmatter.pageTitle}`}</title>
                <meta
                    name="description"
                    content={frontmatter.metaDescription}
                />

                <meta name="twitter:dnt" content="on" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@wemakewaves" />
                <meta name="twitter:title" content={frontmatter.pageTitle} />
                <meta
                    name="twitter:description"
                    content={frontmatter.metaDescription}
                />
                <meta
                    name="twitter:image"
                    content={`${siteUrl}${socialImage}`}
                />

                <meta property="og:title" content={frontmatter.pageTitle} />
                <meta
                    property="og:description"
                    content={frontmatter.metaDescription}
                />
                <meta property="og:url" content={`${siteUrl}${fields.slug}`} />
                <meta
                    property="og:image"
                    content={`${siteUrl}${socialImage}`}
                />

                {frontmatter.nonPublic && (
                    <meta name="robots" content="noindex" />
                )}

                <script type="application/ld+json">
                    {/* tslint:disable */}
                    {JSON.stringify(
                        {
                            '@context': 'https://schema.org',
                            '@type': 'Article',
                            mainEntityOfPage: {
                                '@type': 'WebPage',
                                '@id': `${siteUrl}${fields.slug}`
                            },
                            headline: frontmatter.title,
                            image: [
                                frontmatter.image && frontmatter.image.publicURL
                            ],
                            datePublished: frontmatter.date,
                            dateModified: frontmatter.date,
                            author: {
                                '@type': 'Organization',
                                name: 'We Make Waves'
                            },
                            publisher: {
                                '@type': 'Organization',
                                name: 'We Make Waves',
                                logo: {
                                    '@type': 'ImageObject',
                                    url: wmwSquareLogo
                                }
                            },
                            description: frontmatter.metaDescription,
                            articleBody: excerpt
                        },
                        null,
                        2
                    )}
                    {/* tslint:enable */}
                </script>
            </Helmet>

            <FixedMasthead />

            <BannerAdvert />

            <CaseStudyPageWrapper>
                <PageHeader
                    highlightColor="orange"
                    title={frontmatter.title}
                    lineTitle={frontmatter.name}
                    imageUrl={frontmatter.image && frontmatter.image.publicURL}
                    imageAlt={frontmatter.imageAlt}
                    imageOutlineUrl={
                        frontmatter.imageOutlineShape &&
                        frontmatter.imageOutlineShape.publicURL
                    }
                />

                <section>
                    <MDXRenderer>{body}</MDXRenderer>
                </section>
            </CaseStudyPageWrapper>

            <Constrain>
                <AfterContentContact />
            </Constrain>

            <CallToContact />
        </div>
    );
};

export const pageQuery = graphql`
    query TemplateCaseStudy($slug: String!) {
        site {
            siteMetadata {
                siteUrl
            }
        }

        caseStudy: mdx(fields: { slug: { eq: $slug } }) {
            timeToRead
            excerpt(pruneLength: 10000) # Used for plain text content
            fields {
                slug
            }
            frontmatter {
                date
                title
                summary
                name
                pageTitle
                metaDescription
                image {
                    publicURL
                }
                imageOutlineShape {
                    publicURL
                }
                socialImage {
                    publicURL
                }
                imageAlt
                nonPublic
            }
            id
            body
        }
    }
`;

export default withLayout(CaseStudyPage);
